import {Card, CardContent} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function ProductCard({value}) {
	const navigate = useNavigate();

	return <Card className="inline-block m-3 max-w-xs cursor-pointer" onClick={() => navigate("/product/" + value.id)}
			title={value.title}>
		<CardContent>
			<p className="mb-2 font-bold text-center text-ellipsis whitespace-nowrap overflow-hidden">{value.title}</p>
			<img src={value.imageUrl} alt={value.title}/>
		</CardContent>
	</Card>
}