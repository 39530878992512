import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField} from "@mui/material";
import React from "react";
import {toast} from "react-toastify";
import ProductClient from "../client/product-client";
import AliHelper from "../util/ali-helper";

export default function ProductEditDialog({value, onChange}) {
	const [busy, setBusy] = React.useState(false);

	function close() {
		onChange(ProductEditDialog.closed());
	}

	function setProduct(product) {
		onChange({...value, product: product});
	}

	function generateDescription() {
		setBusy(true);
		ProductClient.generateDescription(value.product.title)
			.then(response => {
				setProduct({
					...value.product,
					description: response?.choices[0]?.message?.content
				});
			}, e => toast.error(e.message))
			.finally(() => {
				setBusy(false);
			});
	}

	function setUrl(url) {
		if (url.startsWith("<a href")) {
			setProduct({
				...value.product,
				...AliHelper.parseAffiliate(url)
			});
		} else {
			setProduct({...value.product, url: url});
		}
	}

	return <Dialog open={Boolean(value.callback)} onClose={() => close()} disableRestoreFocus={true}
			onKeyUp={e => e.key === "Enter" && value.callback(value.product)} maxWidth="">
		<LinearProgress className={busy ? "" : "invisible"}/>
		<DialogTitle>Edit Product {value?.product?.id ?? "(new)"}</DialogTitle>
		<DialogContent sx={{
			"width": "800px",
			"& > :not(style)": {
				m: 1,
				width: "735px"
			}
		}}>
			<TextField id="title" label="Title" value={value.product.title} onChange={e => setProduct({...value.product, title: e.target.value})}
				error={Boolean(value.error)} required/>
			<TextField id="description" label="Description" value={value.product.description ?? ""} multiline rows={20}
				onChange={e => setProduct({...value.product, description: e.target.value})} error={Boolean(value.error)}/>
			<TextField id="url" label="URL" value={value.product.url} onChange={e => setUrl(e.target.value)}
				error={Boolean(value.error)} required/>
			<TextField id="imageURL" label="Image URL" value={value.product.imageUrl} onChange={e => setProduct({...value.product, imageUrl: e.target.value})}
				error={Boolean(value.error)} required/>
			{value.error && <Alert severity="error">{value.error}</Alert>}
		</DialogContent>
		<DialogActions>
			<Button variant="contained" onClick={() => generateDescription()}
				disabled={Boolean(!value.product.title || value.product.description) || busy}>Generate Description</Button>
			<Button variant="contained" onClick={() => value.callback(value.product)}>Save</Button>
			<Button variant="contained" onClick={() => close()}>Cancel</Button>
		</DialogActions>
	</Dialog>
}

ProductEditDialog.closed = function() {
	return {
		error: "",
		product: ProductClient.newProduct()
	};
}