import {Card, CardContent, Tab, Tabs} from "@mui/material";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import CategoryClient from "../client/category-client";
import ProductClient from "../client/product-client";
import ProductCard from "../component/ProductCard";
import "./Home.css";

export default function Category() {
	const navigate = useNavigate();
	const {categoryId} = useParams();
	const [category, setCategory] = React.useState({});
	const [categories, setCategories] = React.useState([]);
	const [products, setProducts] = React.useState([]);

	React.useEffect(() => {
		CategoryClient.find().then(setCategories);
	}, []);

	React.useEffect(() => {
		CategoryClient.load(categoryId).then(setCategory);
	}, [categoryId])

	React.useEffect(() => {
		ProductClient
			.find({
				categoryId: categoryId
			})
			.then(setProducts);
	}, [categoryId]);

	function CategoryEmpty() {
		if (!products.length)
			return <h1 className="text-4xl font-bold text-center m-20">We're sorry, currently there are no products in this category</h1>
	}

	return <div>
		<Tabs className="ml-3 mt-5" value={Number(categoryId)}>
			{categories.map((category, index) =>
				<Tab key={index} value={category.id} label={category.title} onClick={e => navigate("/category/" + category.id)}/>
			)}
		</Tabs>
		<Card>
			<CardContent className="bg-cleveristo">
				<Card className="max-w-4xl m-auto">
					<CardContent className="bg-white">
						<h1 className="text-5xl font-bold mb-16">{category.title}</h1>
						<p className="text-xl whitespace-pre-wrap">{category.description}</p>
					</CardContent>
				</Card>
				{products.map((product, index) => <ProductCard key={index} value={product}/>)}
				<CategoryEmpty/>
			</CardContent>
		</Card>
	</div>
}