import {Card, CardContent} from "@mui/material";
import React from "react";
import UserClient from "../client/user-client";

export default function ManageUsers() {
	const [users, setUsers] = React.useState([]);

	React.useEffect(() => {
		UserClient.load().then(setUsers);
	}, []);

	return <div>
		<Card>
			<CardContent>
					<table className="table-fixed w-full">
						<thead>
							<tr className="bg-gray-300">
								<th className="p-3 w-24">Id</th>
								<th className="p-3 w-1/4 bg-gray-400">Username</th>
								<th className="p-3">Role</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user, index) => <tr key={index}>
								<td className="p-3">{user.id}</td>
								<td className="p-3">{user.username}</td>
								<td className="p-3">{user.role}</td>
							</tr>)}
						</tbody>
					</table>
			</CardContent>
		</Card>
	</div>
}