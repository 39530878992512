import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import UserClient from "../client/user-client";

export default function LoginDialog({open, setOpen = () => {}, onSuccess = () => {}}) {
	const [username, setUsername] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [error, setError] = React.useState("");

	function login() {
		UserClient.login(username, password)
			.then(user => onSuccess(user), e => setError(e.message));
	}

	return <Dialog open={open} onClose={() => setOpen(false)} disableRestoreFocus={true} onKeyUp={e => e.key === "Enter" && login()}>
		<DialogTitle>Login</DialogTitle>
		<DialogContent sx={{
			"width": "300px",
			"& > :not(style)": {
				m: 1,
				width: "25ch"
			}
		}}>
			<TextField id="username" label="Username" value={username} autoFocus onChange={e => setUsername(e.target.value)} error={Boolean(error)}/>
			<TextField id="password" type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} error={Boolean(error)}/>
			{error && <Alert severity="error">{error}</Alert>}
		</DialogContent>
		<DialogActions>
			<Button variant="contained" onClick={() => login()}>Login</Button>
		</DialogActions>
	</Dialog>
}