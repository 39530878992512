import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function Footer() {
	const navigate = useNavigate();

	return <BottomNavigation showLabels className="fixed bottom-0 w-full max-h-8 border-t">
		<BottomNavigationAction label="Imprint" onClick={() => navigate("/imprint")}/>
	</BottomNavigation>
}