import React from "react";
import {toast} from "react-toastify";
import ReactHttp from "../util/react-http";

const UserClient = {
	currentUser: function() {
		return fetch("/service/user/session")
			.then(response => response.text())
			.then(text => {
				return text ? JSON.parse(text) : null;
			}, e => toast.error(e.message));
	},
	login: function(username, password) {
		return fetch("/service/user/login", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				username: username,
				password: password
			})
		})
		.then(response => {
			if (response.status === 200)
				return response.text();
			throw Error("Please try again later");
		})
		.then(text => {
			if (text)
				return JSON.parse(text);
			throw Error("Wrong username or password");
		});
	},
	changePassword: function(username, oldPassword, password, passwordRepeat) {
		return fetch("/service/user/change-password", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				username: username,
				oldPassword: oldPassword,
				password: password,
				passwordRepeat: passwordRepeat
			})
		})
		.then(response => {
			if (response.status !== 200) {
				return response.text()
					.then(text => text ? JSON.parse(text) : {})
					.then(error => {
						throw new Error(error.message ?? "Try again later");
					});
			}
		});
	},
	logout: function() {
		return fetch("/service/user/logout");
	},
	load: function() {
		return ReactHttp.request({
			url: "/service/user/all",
			initial: []
		});
	}
};

export default UserClient;

export function useCurrentUser() {
	const [user, setUser] = React.useState();

	React.useEffect(() => {
		UserClient.currentUser()
			.then(user => setUser(user), e => toast.error(e.message));
	}, []);

	return [user, setUser];
}

export const UserContext = React.createContext();