import {PencilIcon, PlusIcon, TrashIcon} from "@heroicons/react/24/solid";
import {Card, CardContent} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import CategoryClient from "../client/category-client";
import CategoryEditDialog from "../dialog/CategoryEditDialog";
import ConfirmDialog from "../dialog/ConfirmDialog";

export default function ManageCategories() {
	const [editCategory, setEditCategory] = React.useState(CategoryClient.newCategory());
	const [confirm, setConfirm] = React.useState({});
	const [categories, setCategories] = React.useState([]);
	// const [test, setTest] = React.useState({});

	React.useEffect(() => {
		CategoryClient.find().then(setCategories);
	}, []);

	return <div>
		<CategoryEditDialog category={editCategory} setCategory={setEditCategory}/>
		<ConfirmDialog confirm={confirm} setConfirm={setConfirm}/>

		{/* <EditDialog value={test} setValue={setTest} Content={function({value}) {
			return <h1>Value: {value}</h1>;
		}}/>
		<Button variant="contained" onClick={() => setTest({
			title: "Test",
			content: "Hello Content!",
			callback: () => {
				console.log("Callback");
			}
		})}>Test</Button> */}

		<Card>
			<CardContent>
				<div>
					<table className="table-fixed w-full">
						<thead>
							<tr className="bg-gray-300">
								<th className="p-3 w-24">Id</th>
								<th className="p-3 w-1/4 bg-gray-400">Title</th>
								<th className="p-3">Description</th>
								<th className="p-3 w-20 bg-gray-400">
									<PlusIcon className="h-6 cursor-pointer inline-block" onClick={() => setEditCategory({...CategoryClient.newCategory(), id: null})}/>
								</th>
							</tr>
						</thead>
						<tbody>
							{categories.map((category, index) => <tr key={index} className="even:bg-gray-200">
								<td className="p-3">{category.id}</td>
								<td className="p-3">
									<Link to={{pathname: "/manage-products/" + category.id}}>{category.title}</Link>
								</td>
								<td className="p-3">
									<div className="max-h-20 overflow-hidden">{category.description}</div>
								</td>
								<td className='p-3'>
									<PencilIcon className="h-6 cursor-pointer inline" onClick={() => setEditCategory(category)}/>
									<TrashIcon className="h-6 cursor-pointer inline" onClick={() => setConfirm({
										content: "Confirm deleting category " + category.id,
										callback: () => {
											CategoryClient.delete(category.id);
											setCategories([...categories.slice(0, index), ...categories.slice(index + 1)])
											setConfirm({});
										}
									})}/>
								</td>
							</tr>)}
						</tbody>
					</table>
				</div>
			</CardContent>
		</Card>
	</div>
}