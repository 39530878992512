import {Warning} from "@mui/icons-material";
import {Card, CardContent} from "@mui/material";
import React from "react";
import ProductClient from "../client/product-client";
import ProductCard from "../component/ProductCard";
import "./Home.css";

export default function Home() {
	const [products, setProducts] = React.useState([]);

	React.useEffect(() => {
		ProductClient.find().then(setProducts);
	}, []);

	return <div>
		<h1 className="text-5xl font-bold bg-white m-5 p-5 border-black border-2 text-center">
			<Warning color="warning" fontSize="48"/>
			<span> Under Construction! </span>
			<Warning color="warning" fontSize="48"/>
		</h1>
		<Card className="mt-8 mb-4 ml-96 p-5" style={{width: "500px"}}>
			<CardContent>
				<h1 className="text-5xl font-bold leading-tight">The new way of finding products</h1>
				<h2 className="text-3xl mt-4">Discover the latest</h2>
			</CardContent>
		</Card>
		<div className="m-auto" style={{width: "1380px"}}>
			{products.map((product, index) => <ProductCard key={index} value={product}/>)}
		</div>
	</div>
}