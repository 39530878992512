
import React from "react";

export default class Catch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: null, showStack: false};
	}

	static getDerivedStateFromError(error) {
		return {error: error};
	}
	componentDidCatch(_error, info) {
		this.setState({info: info});
	}
	render() {
		if (this.state.error) {
			return this.props.error ?? <div className="border-2 border-dashed border-gray-400 m-3 p-20 bg-gray-200 text-center">
				<h1 className="text-5xl font-bold text-gray-600">Error loading component</h1>
				{this.state.showStack ?
					<code className="whitespace-pre text-left inline-block mt-5">
						{this.state.error.message}
						{this.state.info.componentStack}
					</code> :
					<p className="mt-3">{this.state.error.message}</p>
				}
				<div className="mt-6">
					<button className="btn" onClick={() => this.setState({error: null, showStack: false})}>Try again</button>
					{this.state.showStack ?
						<button className="btn" onClick={() => this.setState({showStack: false})}>Hide Stack</button> :
						<button className="btn" onClick={() => this.setState({showStack: true})}>Show Stack</button>
					}
				</div>
			</div>
		}
		return this.props.children;
	}
}