
const AliHelper = {
	parseAffiliate: function(text) {
		const el = document.createElement("div");
		el.innerHTML = text;
		return {
			url: el.firstChild.href,
			imageUrl: AliHelper.trim(el.firstChild.firstChild.attributes.src.value)
		};
	},
	trim: function(url) {
		const parsed = /"*([^"]+)"*/.exec(url);
		return parsed ? parsed[1] : url;
	},
	fullImage: function(url) {
		const parsed = /(.+)_\d+x\d+\..+/.exec(url);
		return parsed ? parsed[1] : url;
	}
};

export default AliHelper;