import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField} from "@mui/material";
import React from "react";
import {toast} from "react-toastify";
import CategoryClient from "../client/category-client";

export default function CategoryEditDialog({category, setCategory}) {
	const [busy, setBusy] = React.useState(false);
	const [error, setError] = React.useState("");

	function close() {
		setCategory(CategoryClient.newCategory());
	}

	function save() {
		CategoryClient.save(category)
			.then(() => close(), e => setError(e.message));
	}

	function generateDescription() {
		setBusy(true);
		CategoryClient.generateDescription(category.title)
			.then(response => {
				setCategory({
					...category,
					description: response?.choices[0]?.message?.content
				});
			}, e => toast.error(e.message))
			.finally(() => {
				setBusy(false);
			});
	}

	return <Dialog open={"id" in category} onClose={() => setCategory(CategoryClient.newCategory())} disableRestoreFocus={true}
			onKeyUp={e => e.key === "Enter" && save()} maxWidth="">
		<LinearProgress className={busy ? "" : "invisible"}/>
		<DialogTitle>Edit Category {category.id ?? "(new)"}</DialogTitle>
		<DialogContent sx={{
			"width": "800px",
			"& > :not(style)": {
				m: 1,
				width: "735px"
			}
		}}>
			<TextField id="title" label="Title" value={category.title} onChange={e => setCategory({...category, title: e.target.value})}
				error={Boolean(error)} required/>
			<TextField id="description" label="Description" value={category.description} onChange={e => setCategory({...category, description: e.target.value})}
				error={Boolean(error)} required multiline rows={20}/>
			{error && <Alert severity="error">{error}</Alert>}
		</DialogContent>
		<DialogActions>
		<Button variant="contained" onClick={() => generateDescription()}
				disabled={Boolean(!category.title || category.description) || busy}>Generate Description</Button>
			<Button variant="contained" onClick={() => close()}>Cancel</Button>
			<Button variant="contained" onClick={() => save()}>Save</Button>
		</DialogActions>
	</Dialog>
}