import React from "react";
import {useNavigate} from "react-router-dom";

export default function NotFound() {
	const navigate = useNavigate();

	React.useEffect(() => {
		const timer = setTimeout(() => {
			navigate("/", {
				replace: true
			});
		}, 10000)
		return () => clearTimeout(timer);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return <h1 className='text-5xl font-bold text-center mt-20 text-red-600'>Not Found</h1>;
}