import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";

export default function ConfirmDialog({confirm: {
		content = "Please confirm",
		title = "Confirm",
		action = "Confirm",
		cancel = "Cancel",
		callback
	}, setConfirm}) {
	return <Dialog open={Boolean(callback)} onClose={() => setConfirm({})} disableRestoreFocus={true} onKeyUp={e => e.key === "Enter" && callback()} maxWidth="">
		<DialogTitle>{title ?? "Confirm"}</DialogTitle>
		<DialogContent sx={{
			"width": "800px",
			"& > :not(style)": {
				m: 1,
				width: "735px"
			}
		}}>
			{content}
		</DialogContent>
		<DialogActions>
			<Button variant="contained" onClick={() => callback()}>{action}</Button>
			<Button variant="contained" onClick={() => setConfirm({})}>{cancel}</Button>
		</DialogActions>
	</Dialog>;
}