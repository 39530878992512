/* eslint-disable react-hooks/exhaustive-deps */
import objects from "@intersult/objects";
import React from "react";

const ReactHttp = {
	usePromise: function(promise, initial, deps = []) {
		const [value, setValue] = React.useState(initial);
		React.useEffect(() => {
			promise.then(setValue);
		}, deps);
		return [value, setValue];
	},
	useData: function(arg, deps = []) {
		const [data, setData] = React.useState(arg.initial);
		React.useEffect(() => {
			ReactHttp.request(arg)
				.then(data => setData(data), error => console.error(error));
		}, deps);
		return data;
	},
	request: function({url, params, body, options}) {
		return fetch(url + this.toParamString(params), {...options, ...ReactHttp.toOptions(body)})
			.then(response => ReactHttp.toData(response));
	},
	toData: function(response) {
		return response.text()
			.then(text => {
				const json = text ? JSON.parse(text) : undefined;
				if (response.status === 200)
					return json;
				console.error(response.status, json ?? text);
				throw Error(json ? json.error : "Try again later");
			});
	},
	toParamString: function(params) {
		return objects.empty(params) ? "" : "?" + new URLSearchParams(params);
	},
	toOptions: function(body) {
		if (body) {
			return {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(body)
			}
		}
	}
};
export default ReactHttp;