import dayjs from "dayjs";
import ReactHttp from "../util/react-http";

const ProductClient = {
	find: function(params) {
		return ReactHttp.request({
			url: "/service/product/find",
			params: params,
			initial: []
		});
	},
	load: function(productId) {
		return ReactHttp.request({
			url: "/service/product/get",
			params: {
				productId: productId
			}
		});
	},
	save: function(product) {
		return ReactHttp.request({
			url: "/service/product/save",
			body: product
		});
	},
	delete: function(productId) {
		return ReactHttp.request({
			url: "/service/product/delete",
			params: {
				productId: productId
			}
		});
	},
	generateDescription: function(title) {
		return ReactHttp.request({
			url: "/service/product/generate-description",
			params: {
				title: title
			}
		});
	},
	newProduct: function(categoryId) {
		return {
			title: "",
			description: "",
			url: "",
			imageUrl: "",
			categoryId: categoryId,
			listedSince: dayjs().toISOString()
		};
	}
};

export default ProductClient;