import {Card, CardContent} from "@mui/material";

export default function Imprint() {
	return <Card>
		<CardContent>
			<h1 className="text-3xl font-bold mb-2">Imprint</h1>
			<p>
				<b>Intersult GmbH</b><br/>
				Boernestr. 27<br/>
				90475 Nuernberg<br/>
				Germany<br/>
			</p>
			<h1 className="text-3xl font-bold mt-4 mb-2">Managing Director</h1>
			<p>Dieter Kaeppel</p>
			<h1 className="text-3xl font-bold mt-4 mb-2">Register Court</h1>
			<p>
				HRB 23669<br/>
				Nuernberg, Germany<br/>
			</p>
			<h1 className="text-3xl font-bold mt-4 mb-2">Tax Number</h1>
			<p>DE256689986</p>
		</CardContent>
	</Card>
}