import {AccountCircle, Home} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import React from "react";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import CategoryClient from "../client/category-client";
import UserClient, {UserContext} from "../client/user-client";
import ChangePasswordDialog from "../dialog/ChangePasswordDialog";
import LoginDialog from "../dialog/LoginDialog";

export default function MenuBar({routes}) {
	const navigate = useNavigate();
	const location = useLocation();
	const currentRoute = routes.find(route => matchPath(route, location.pathname));

	const [menu, setMenu] = React.useState();
	const [categoryMenu, setCategoryMenu] = React.useState();
	const [userMenu, setUserMenu] = React.useState();
	const [loginOpen, setLoginOpen] = React.useState(false);
	const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
	const [user, setUser] = React.useContext(UserContext);
	const [categories, setCategories] = React.useState([]);

	React.useEffect(() => {
		document.title = "Cleveristo - " + (currentRoute.title ?? "Not Found");
	}, [currentRoute]);
	React.useEffect(() => {
		CategoryClient.find().then(setCategories);
	}, []);
	
	function login() {
		setUserMenu(null);
		setLoginOpen(true);
	}

	function changePassword() {
		setUserMenu(null);
		setChangePasswordOpen(true);
	}
	
	function logout() {
		UserClient.logout()
			.then(() => {
				toast.info("Logged out user: " + user.username);
				setUserMenu(null);
				setUser(null);
				navigate("/");
			});
	}

	function openCategory(category) {
		setCategoryMenu(null);
		navigate("/category/" + category.id);
	}

	function UserMenu() {
		return <Menu id="account-menu" open={Boolean(userMenu)} onClose={() => setUserMenu(null)} anchorEl={userMenu}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}} disableScrollLock>
			<MenuItem onClick={changePassword}>Change password</MenuItem>
			<MenuItem onClick={logout}>Logout</MenuItem>
		</Menu>
	}

	function AnonymousMenu() {
		return <Menu id="account-menu" open={Boolean(userMenu)} onClose={() => setUserMenu(null)} anchorEl={userMenu}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}} disableScrollLock>
			<MenuItem onClick={login}>Login</MenuItem>
		</Menu>
	}

	return <div>
		{loginOpen && <LoginDialog open={loginOpen} setOpen={setLoginOpen} onSuccess={user => {
			setUser(user);
			setLoginOpen(false);
			toast.info("Logged in user: " + user.username);
		}}/>}
		{changePasswordOpen && <ChangePasswordDialog key={changePasswordOpen} open={changePasswordOpen} user={user} setOpen={setChangePasswordOpen} onSuccess={() => {
			setChangePasswordOpen(false);
			toast.info("Password changed for user: " + user.username);
		}}/>}
		<AppBar position="fixed">
			<Toolbar>
				{location.pathname !== "/" && <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}} onClick={() => navigate("/")} title="Home">
					<Home/>
				</IconButton>}
				{user && <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}} onClick={e => setMenu(e.currentTarget)}>
					<MenuIcon/>
				</IconButton>}
				<Menu id="menu" open={Boolean(menu)} onClose={() => setMenu(null)} anchorEl={menu}
						anchorOrigin={{vertical: "bottom", horizontal: "left"}} disableScrollLock>
					<MenuItem onClick={() => {setMenu(null); navigate("/manage-categories");}}>Manage Categories</MenuItem>
					<MenuItem onClick={() => {setMenu(null); navigate("/manage-users");}}>Manage Users</MenuItem>
				</Menu>
				<Typography variant="h6" component="div" sx={{mr: 4, cursor: "pointer"}} onClick={e => setCategoryMenu(e.currentTarget)}>Categories</Typography>
				<Menu id="cetegories-menu" open={Boolean(categoryMenu)} onClose={() => setCategoryMenu(null)} anchorEl={categoryMenu}
						anchorOrigin={{vertical: "bottom", horizontal: "left"}} disableScrollLock>
					{categories.map((category, index) =>
						<MenuItem key={index} onClick={e => openCategory(category)}>{category.title}</MenuItem>
					)}
				</Menu>
				<Typography variant="h6" component="div" sx={{flexGrow: 1}}>[{currentRoute.title ?? "Not Found"}]</Typography>
				{user && <Typography variant="h6" component="div" edge="end">{user.username}</Typography>}
				<IconButton size="large" edge="end" onClick={e => setUserMenu(e.currentTarget)} color="inherit">
					<AccountCircle/>
				</IconButton>
				{user ? <UserMenu/> : <AnonymousMenu/>}
			</Toolbar>
		</AppBar>
	</div>
}