import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/de";
import React, {StrictMode} from 'react';
import CookieConsent from 'react-cookie-consent';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import {UserContext, useCurrentUser} from './js/client/user-client';
import Catch from './js/component/Catch';
import Footer from './js/component/Footer';
import MenuBar from './js/component/MenuBar';
import Category from './js/page/Category';
import Home from './js/page/Home';
import Imprint from './js/page/Imprint';
import ManageCategories from './js/page/ManageCategories';
import ManageProducts from './js/page/ManageProducts';
import ManageUsers from './js/page/ManageUsers';
import NotFound from './js/page/NotFound';
import Product from './js/page/Product';

const routes = [{
	path: "/",
	title: "Home",
	element: <Home/>
}, {
	path: "/category/:categoryId",
	title: "Category",
	element: <Category/>
}, {
	path: "/manage-categories",
	title: "Manage Categories",
	element: <ManageCategories/>
}, {
	path: "/manage-products/:categoryId",
	title: "Manage Products",
	element: <ManageProducts/>
}, {
	path: "/manage-users",
	title: "Manage Users",
	element: <ManageUsers/>
}, {
	path: "/imprint",
	title: "Imprint",
	element: <Imprint/>
}, {
	path: "/product/:productId",
	title: "Product",
	element: <Product/>
}, {
	path: "*",
	element: <NotFound/>
}];

const root = ReactDOM.createRoot(document.getElementById('root'));
const script = new URL(document.scripts[document.scripts.length - 1].src);
const basepath = script.pathname.substring(0, script.pathname.lastIndexOf("/") + 1);
const language = navigator?.language.substring(0, 2);

function Cleveristo() {
	return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
		<UserContext.Provider value={useCurrentUser()}>
			<BrowserRouter basename={process.env.NODE_ENV === "development" ? "/" : basepath}>
				<MenuBar routes={routes}/>
				<div className="p-3">
					<Routes>
						{routes.map(route =>
							<Route key={route.path} {...route} element={
								<Catch key={route.path}>{route.element}</Catch>
							}/>
						)}
					</Routes>
				</div>
				<Footer/>
			</BrowserRouter>
			<ToastContainer position="bottom-right"/>
			<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
		</UserContext.Provider>
	</LocalizationProvider>
}

root.render(
	<StrictMode>
		<Cleveristo/>
	</StrictMode>
);