import {ChevronLeft} from "@mui/icons-material";
import {Card, CardContent} from "@mui/material";
import React from "react";
import {Link, useParams} from "react-router-dom";
import CategoryClient from "../client/category-client";
import ProductClient from "../client/product-client";
import AliHelper from "../util/ali-helper";

export default function Product() {
	const {productId} = useParams();
	const [product, setProduct] = React.useState({});
	const [category, setCategory] = React.useState({});

	React.useEffect(() => {
		ProductClient.load(productId).then(setProduct);
	}, [productId]);

	React.useEffect(() => {
		product.categoryId && CategoryClient.load(product.categoryId).then(setCategory);
	}, [product]);

	return <div>
		<Card className="max-w-4xl m-auto">
			<CardContent className="bg-white">
				<Link to={"/category/" + product.categoryId}>
					<ChevronLeft className="h-6 inline"/>
					{"go back to " + category.title}
				</Link>
				{/* <h1 className="text-3xl font-bold p-5 text-center">{product.title}</h1> */}
				<p className="p-8 text-xl whitespace-pre-wrap">{product.description}</p>
				<Link to={product.url} target="_blank" className="block m-auto w-full">
					<img src={AliHelper.fullImage(product.imageUrl)} alt={product.title} className="w-full"/>
					<span className="block text-center font-bold">{product.title}</span>
				</Link>
			</CardContent>
		</Card>
	</div>
}