import {EyeIcon, PencilIcon, PlusIcon, TrashIcon} from "@heroicons/react/24/solid";
import {Card, CardContent, Tab, Tabs} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import CategoryClient from "../client/category-client";
import ProductClient from "../client/product-client";
import ConfirmDialog from "../dialog/ConfirmDialog";
import ProductEditDialog from "../dialog/ProductEditDialog";

export default function ManageProducts() {
	const navigate = useNavigate();
	const {categoryId} = useParams();
	const [categories, setCategories] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [editProduct, setEditProduct] = React.useState(ProductEditDialog.closed());
	const [confirm, setConfirm] = React.useState({});

	React.useEffect(() => {
		CategoryClient.find().then(setCategories);
	}, []);

	React.useEffect(() => {
		ProductClient
			.find({
				categoryId: categoryId
			})
			.then(products => setProducts(products));
	}, [categoryId]);

	return <div>
		<ProductEditDialog value={editProduct} onChange={setEditProduct}/>
		<ConfirmDialog confirm={confirm} setConfirm={setConfirm}/>
		<Tabs className="ml-3" value={Number(categoryId)} onChange={(_event, value) => navigate("/manage-products/" + value)}>
			{categories.map((category, index) =>
				<Tab key={index} value={category.id} label={category.title}/>
			)}
		</Tabs>
		<Card>
			<CardContent>
				<div>
					<table className="table-fixed w-full break-words">
						<thead>
							<tr className="bg-gray-300">
								<th className="p-3 w-24">Id</th>
								<th className="p-3 w-1/4 bg-gray-400">Title</th>
								<th className="p-3 w-28">Since</th>
								<th className="p-3 bg-gray-400">Description</th>
								<th className="p-3">URL</th>
								<th className="p-3 bg-gray-400">Image URL</th>
								<th className="p-3 w-24">
									<PlusIcon className="h-6 cursor-pointer inline-block" onClick={() => setEditProduct({
										product: ProductClient.newProduct(categoryId),
										callback: product => ProductClient
											.save(product)
											.then(product => {
												setProducts([product, ...products]);
												setEditProduct(ProductEditDialog.closed());
											}, e => {
												setEditProduct({...editProduct, error: e.message});
											})
									})}/>
								</th>
							</tr>
						</thead>
						<tbody>
							{products.map((product, index) =>
								<tr key={index} className="even:bg-gray-200 max-h-20 align-top">
									<td className="p-3">{product.id}</td>
									<td className="p-3">{product.title}</td>
									<td className="p-3">{dayjs(product.listedSince).format("DD.MM.YYYY")}</td>
									<td className="p-3">
										<div className="max-h-20 overflow-hidden">{product.description}</div>
									</td>
									<td className="p-3">{product.url}</td>
									<td className="p-3">{product.imageUrl}</td>
									<td className='p-3'>
										<PencilIcon className="h-6 cursor-pointer inline" onClick={() => setEditProduct({
											product: {...product},
											callback: product => {
												ProductClient.save(product);
												setProducts([...products.slice(0, index), product, ...products.slice(index + 1)])
												setEditProduct(ProductEditDialog.closed());
											}
										})}/>
										<TrashIcon className="h-6 cursor-pointer inline" onClick={() => setConfirm({
											content: "Confirm deleting prduct " + product.id,
											callback: () => {
												ProductClient.delete(product.id);
												setProducts([...products.slice(0, index), ...products.slice(index + 1)])
												setConfirm({});
											}
										})}/>
										<EyeIcon className="h-6 cursor-pointer inline" onClick={() => navigate("/product/" + product.id)}/>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</CardContent>
		</Card>
	</div>
}