import ReactHttp from "../util/react-http";

const CategoryClient = {
	find: function() {
		return ReactHttp.request({
			url: "/service/category/all",
			initial: []
		});
	},
	load: function(categoryId) {
		return ReactHttp.request({
			url: "/service/category/get",
			params: {
				categoryId: categoryId
			}
		});
	},
	save: function(category) {
		return ReactHttp.request({
			url: "/service/category/save",
			body: category
		});
	},
	delete: function(categoryId) {
		return ReactHttp.request({
			url: "/service/category/delete",
			params: {
				categoryId: categoryId
			}
		});
	},
	generateDescription: function(title) {
		return ReactHttp.request({
			url: "/service/category/generate-description",
			params: {
				title: title
			}
		});
	},
	newCategory: function() {
		return {
			title: "",
			description: ""
		}
	}
};

export default CategoryClient;