import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import UserClient from "../client/user-client";

export default function ChangePasswordDialog({user, open, setOpen = () => {}, onSuccess = () => {}}) {
	const [oldPassword, setOldPassword] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [passwordRepeat, setPasswordRepeat] = React.useState("");
	const [error, setError] = React.useState("");

	function changePassword() {
		UserClient.changePassword(user.username, oldPassword, password, passwordRepeat)
			.then(user => onSuccess(), e => setError(e.message));
	}

	return <Dialog open={open} onClose={() => setOpen(false)} disableRestoreFocus={true} onKeyUp={e => e.key === "Enter" && changePassword()}>
		<DialogTitle>Change Password</DialogTitle>
		<DialogContent sx={{
			"width": "300px",
			"& > :not(style)": {
				m: 1,
				width: "25ch"
			}
		}}>
			<TextField id="old-assword" type="password" label="Old Password" value={oldPassword} onChange={e => setOldPassword(e.target.value)}
				error={Boolean(error)} required/>
			<TextField id="password" type="password" label="New Password" value={password} onChange={e => setPassword(e.target.value)}
				error={Boolean(error)} required/>
			<TextField id="password-repeat" type="password" label="Repeat Password" value={passwordRepeat}
				onChange={e => setPasswordRepeat(e.target.value)} error={Boolean(error)} required/>
			{error && <Alert severity="error">{error}</Alert>}
		</DialogContent>
		<DialogActions>
			<Button variant="contained" onClick={() => changePassword()}>Change</Button>
		</DialogActions>
	</Dialog>
}